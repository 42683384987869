@import url("https://fonts.googleapis.com/css?family=Mukta:200,275,300,400,500,600,700,800&display=swap");

$body-bg: #ffffff;
$font-family-base: "Mukta";
$font-size-base: 1.1rem;
$font-size-lg: 1.25rem;
$font-size-sm: 0.75rem;
$primary: #4e7f5e;
$secondary: rgba(245, 243, 238, 0.5);
$danger: #ce5444;
$info: #419cb9;

//TODO Remove - Dirty Override for Shop
.useRem{
  font-size: clamp(8px,0.694vw,10px);
}
@media (max-width:540px) {
  .useRem{
  font-size: clamp(9px,2.7vw,12px);  
  }
}

/////
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1120px,
  // xl: 960px,
  // xxl: 960px,
);

$container-padding-x: 2rem;

$headings-color: #2e2e2e;

$navbar-light-bg-color: #ffffff;
$navbar-light-hover-color: #ffffff;

$form-check-input-border: none;
$form-check-input-checked-color: $primary;
$form-check-input-checked-bg-color: $primary;
.form-check-input:checked[type="checkbox"] {
  background-color: $secondary !important;
}
$input-bg: $secondary;
$input-border-color: $secondary;
$input-border-radius: 8px;
$input-placeholder-color: rgba(0, 0, 0, 0.3);

$input-color: rgba(0, 0, 0, 0.5);
$form-check-label-color: rgba(0, 0, 0, 0.5);

$input-font-weight: 300;

$form-label-font-weight: 500;
$form-label-font-size: calc(1.3175rem + 0.81vw);
$form-check-input-width: 1.75em;
$form-check-min-height: 2.25em;
.form-label{
  line-height: 1.2em;
}

$btn-border-radius: 8px;
$btn-font-weight: 700;
$btn-padding-x: 1.2em;
$btn-padding-y: 0.75em;
$btn-disabled-bg: #8A8A8A;
$btn-border-width: 1px;


.btn {
  letter-spacing: 2px;

  //animate the color from disabled state to enabled state
  transition: background-color 0.5s ease-in !important;
  transition: border 0.25s ease-in !important;

  &:disabled {
    border: 1px solid #8A8A8A !important;
    background-color: #8A8A8A !important;
  }

  &:active{
    border:#2e2e2e 1px solid;
    border-color:#2e2e2e;
    background-color: #fff !important;
    color: #2e2e2e !important;
  }
}


$list-group-bg: unset;

$accordion-border-width: 0px;
$accordion-button-active-bg: unset;
$accordion-button-focus-border-color: unset;
$accordion-button-focus-box-shadow: unset;
$accordion-body-padding-x: 1rem;
$accordion-padding-y: 1rem;
$accordion-button-padding-y: 1rem;
$accordion-button-padding-x: 1rem;
$accordion-bg: transparent;
.accordion-button {
  font-size: $font-size-base * 1.2 !important;
  font-weight: 200;
}
.accordion-body {
  color: rgba(0, 0, 0, 0.5);
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.container form select {
  height: 5rem;
}

// .container form {
// }

hr {
  color: #4e7f5e !important;
  height: 3px !important;
  opacity: 1 !important;
  margin: 0 0 1rem 0 !important;
}

.card {
  background-color: $secondary;
  border-radius: 8px !important;
  overflow: hidden;
}

.card .card-body {
  padding: 0px;
  font-weight: 200;
}

.card img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.homePageCommonContainer{
  padding-left: clamp(5%,calc((100% - 1300px) / 2),20%);
  padding-right: clamp(5%,calc((100% - 1300px) / 2),20%);
  // padding-right: 5% ;
  // max-width: 1400px;
  // margin: 0 auto;
}

.stepperContainer{
  line-height: 1em !important;
}

@import "../node_modules/bootstrap/scss/bootstrap";
