.account_navbar {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    margin-bottom: 15px;
}

.top_nav_shop_name_wrapper {
    gap: 32px;
    display: flex;
    cursor: pointer;
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 300;
    line-height: 150%;
    font-style: normal;
    align-items: center;
    flex-direction: row;
    font-family: 'Mukta';
    justify-content: flex-end;
}

.hamburger_menu_button {
    gap: 7px;
    border: none;
    padding: 10px;
    display: flex;
    background: none;
    flex-direction: column;
}

.hamburger_menu_line {
    width: 30px;
    height: 3px;
    background-color: #2e2e2e;
}

.custom_toggle_button {
    display: none;
}

.top_nav_logo_brand {
    gap: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.top_nav_relove_logo {
    height: auto;
    width: clamp(75px, 63.64px + 3.03vw, 100px);
}

.top_nav_logo_brand_text {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 400;
    line-height: 176%;
    font-style: normal;
    font-family: 'Mukta';
}

.top_nav_shop_link {
    color: #2e2e2e;
    font-weight: 400;
    gap: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    justify-content: center;
}

.user_image {
    border-radius: 50%;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

.nav_user_email_text {
    margin: 0px;
    width: 100%;
    font-size: 12px;
    line-height: 120%;
    text-align: right;
}

.email_text_desktop {
    display: block;
}

.email_text_mobile {
    display: none;
}

.log_out_nav_menu {
    display: none;
}

.top_nav_shop_link:hover {
    color: #2e2e2e;
}

.top_nav_shop_link:visited {
    color: #2e2e2e;
}

.top_nav_user_area_wrapper {
    gap: 36px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.drawer_menu_text {
    margin-right: 16px;
    font-size: 23px;
    color: #505050;
    font-weight: 500;
    padding-top: 20px;
    padding-left: 40px;
    padding-bottom: 20px;
    border-bottom: 2px solid #505050;
}

.bootstrap_offcanvas {
    max-width: 100%;
}

.nav_email_menu_separator {
    border: none;
    margin: 10px;
    border-bottom: 1px solid #CCCCCC;
}

@media screen and (max-width: 1200px) {
    .custom_toggle_button {
        display: block;
    }

    .top_nav_shop_name_wrapper {
        align-items: flex-end;
        flex-direction: column;
    }

    .log_out_nav_menu {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }

    .account_icon {
        display: none;
    }

    .bootstrap_offcanvas {
        max-width: 70%;
    }

    .account_navbar {
        margin-left: 16px;
        margin-right: 16px;
    }

    .email_text_desktop {
        display: none;
    }

    .email_text_mobile {
        display: block;
    }
}